'use client';

import React, { useMemo } from 'react';

import clsx from 'clsx';

import SortIcon from 'public/assets/icons/sort.svg';
import SortAscIcon from 'public/assets/icons/sort-asc.svg';
import SortDescIcon from 'public/assets/icons/sort-desc.svg';
import { Popover, RadioGroup } from '@radix-ui/themes';
import { cn, makeMap } from '@/utils';
import ArrowDownIcon from 'public/arrow-down.svg';
import { StorageService } from '@/service/StorageService';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { AndroidService } from '@/service/Android';
import { SORT_CHIP_CLICKED, SORT_UPDATED } from '@/constants/logs';

export default function OfferSort({ onSubmit, activeSort, activeTags }) {
  const isSortActive = !!activeSort;
  const SORT_OPTIONS = GET_SORT_OPTIONS(StorageService.getLanguage());

  const sortMap = useMemo(() => {
    return makeMap(SORT_OPTIONS, d => d.id);
  }, [SORT_OPTIONS]);

  const handleSort = id => {
    if (id === sortMap.default.id) {
      onSubmit(null);
      return;
    }
    if (id !== activeSort) {
      AndroidService.logEvent(
        SORT_UPDATED,
        JSON.stringify({
          sort_opts: id || 'default',
          filter_opts: activeTags.join(','),
          user_id: StorageService.getUserId(),
        })
      );
    }
    onSubmit(id);
  };

  const activeOption = sortMap[activeSort];

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div
          className={clsx(
            'rounded-full border-[1px] border-accent-color w-fit px-2 py-1 flex items-center gap-1 ',
            isSortActive && 'bg-accent-color'
          )}
          onClick={() => {
            AndroidService.logEvent(
              SORT_CHIP_CLICKED,
              JSON.stringify({
                user_id: StorageService.getUserId(),
              })
            );
          }}
        >
          <div className="flex items-center gap-1">
            {isSortActive ? (
              <>
                {activeOption.type === 'asc' ? (
                  <SortAscIcon className="text-white" />
                ) : (
                  <SortDescIcon className="text-white" />
                )}
                <p className="text-sm text-white">{activeOption.display}</p>
                <ArrowDownIcon className="text-sm text-white" />
              </>
            ) : (
              <>
                <SortIcon className={clsx('text-accent-color')} />
                <p className={clsx('text-sm ', 'text-accent-color')}>
                  {
                    LANGUAGES_CONTENT[StorageService.getLanguage()].home.sort
                      .title
                  }
                </p>
                <ArrowDownIcon className="text-sm text-accent-color" />
              </>
            )}
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Content
        width="360px"
        className="border border-accent-color bg-[var(--violet-4)] p-3"
      >
        <div className="flex flex-col gap-2">
          {SORT_OPTIONS.map((d, i) => (
            <Popover.Close>
              <div
                key={d.id}
                onClick={() => handleSort(d.id)}
                className={cn(
                  'flex items-center gap-2 pb-1',
                  i === SORT_OPTIONS?.length - 1
                    ? 'border-none'
                    : 'border-b border-accent-color'
                )}
              >
                <p className="text-sm text-accent-color">
                  {d.display}
                  {!!d.type
                    ? d.type === 'asc'
                      ? `: ${
                          LANGUAGES_CONTENT[StorageService.getLanguage()].home
                            .sort.lowToHigh
                        }`
                      : `: ${
                          LANGUAGES_CONTENT[StorageService.getLanguage()].home
                            .sort.highToLow
                        }`
                    : null}
                </p>
                <RadioGroup.Root
                  variant="classic"
                  defaultValue={
                    d.id === 'default' && activeSort === null
                      ? d.id
                      : activeSort === d.id
                      ? d.id
                      : null
                  }
                  color="violet"
                  className="h-4 ml-auto"
                  size="1"
                >
                  <RadioGroup.Item
                    value={d.id}
                    className={
                      activeSort === null && d.id === 'default'
                        ? 'bg-accent-color'
                        : d.id === activeSort
                        ? 'bg-accent-color'
                        : 'bg-white'
                    }
                  />
                </RadioGroup.Root>
              </div>
            </Popover.Close>
          ))}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

const GET_SORT_OPTIONS = (lang = 'ENGLISH') => [
  {
    id: 'default',
    display: LANGUAGES_CONTENT[lang].home.sort.default,
  },
  {
    id: 'ttfp',
    display: LANGUAGES_CONTENT[lang].home.sort.ttfp,
    type: 'asc',
  },
  {
    id: '-ttfp',
    display: LANGUAGES_CONTENT[lang].home.sort.ttfp,
    type: 'desc',
  },
  {
    id: '-payout',
    display: LANGUAGES_CONTENT[lang].home.sort.price,
    type: 'desc',
  },
  {
    id: 'payout',
    display: LANGUAGES_CONTENT[lang].home.sort.price,
    type: 'asc',
  },
];
